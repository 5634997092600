import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layouts/Blog/BlogPost/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Hi Dev.to friends,`}</p>
    <p>{`Do you think that software development is teamwork? I do think so.`}</p>
    <p>{`One team, full of talented people. Some people are more experienced than others. Some people are just new in the team, either because just started in your company or because it is their first job as a software developer.`}</p>
    <p>{`When starting your career as a software developer, everything seems overwhelming. It feels you are never good enough.`}</p>
    <figure style={{
      position: "relative",
      overflow: "hidden",
      width: "100%",
      paddingTop: "56.25%"
    }}>
    <iframe src="https://www.youtube.com/embed/-ENU1vjNSdQ" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: "100%",
        height: "100%"
      }}></iframe>
    <figcaption className="md-figure-caption">Help Junior Developers</figcaption>
    </figure>
    <p>{`That could put lots of pressure and stress on junior developers. They might underperform because of all this.`}</p>
    <p>{`That’s why I think it is one of our duties as a senior developer to help and support less experienced co-workers during their daily job.
We need to help them to reach our level and knowledge, even faster than how we reach it.`}</p>
    <p>{`There are a few suggestions that I have for experienced developers on how to support junior developers during their daily job.`}</p>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/7200/1*VZkw1KyI7sBihW1dmQCmlQ.jpeg",
        "alt": "Alt text of image"
      }}></img></p>
    <h3>{`MAKE YOURSELF AVAILABLE`}</h3>
    <p>{`The first thing that you can do as a senior developer, being available to your less experienced teammates.`}</p>
    <p>{`You should make yourself available to answer questions: your less experienced co-workers might have lots of questions and doubts. Are you available to answer all of them?`}</p>
    <p>{`You should not be the guy saying that you have no time!
It is your duty as a senior developer to help and support your fellow junior teammates.`}</p>
    <p>{`A small answer, a small help from your side could help them deeply.
Often, a junior developer might be under stress and pressure to perform: with the right mentorship and help from more experienced developers, it can make all the difference in their career.`}</p>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/12032/1*XXI-kg18liPn4XcfZmoqQQ.jpeg",
        "alt": "Alt text of image"
      }}></img></p>
    <h3>{`MISTAKES`}</h3>
    <p>{`Let’s talk about mistakes: we have all been junior developers and we all made mistakes.`}</p>
    <p>{`I did my amount of mistakes in the past and also today, it happens from time to time.`}</p>
    <p>{`Mistakes are a good source for learning how to do things and make the right experience.
If you are learning from your mistakes and errors, you are getting a very good experience.`}</p>
    <p>{`What if they make the same mistakes over and over?`}</p>
    <p>{`In that case, we need to be better to explain why their decision was a bad decision for a given problem, what they could have done instead and how to attack the same problem if that problem arises again.`}</p>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/10368/1*lytSQVSSS7G3mEu26O6p-A.jpeg",
        "alt": "Alt text of image"
      }}></img></p>
    <h3>{`CODE REVIEWS`}</h3>
    <p>{`One thing that I will never be tired to say is to do code reviews. Do them often.`}</p>
    <p>{`They are such a great opportunity to point out best practices within the team and in software development!`}</p>
    <p>{`Having someone double-checking our work never hurts, and limits the risk of unnoticed mistakes. Even good developers get tunnel vision sometimes.
Software development is teamwork, and being able to double-check each code is so important. It helps to improve the codebase that we are working on altogether.`}</p>
    <p>{`Someone could suggest a smarter solution, a more appropriate design pattern, a way to reduce complexity or to improve performance.`}</p>
    <p>{`By joining forces, everyone can learn and get better. The code submitter is likely to receive feedback on their work, making them aware of possible problems and areas for improvement. The reviewers could well learn new things by reading through the code and figure out solutions applicable to their own work.`}</p>
    <p>{`The point is that a code review is asking for improvement: improve the codebase, improve the understanding of the codebase, the best practices inside the team or just understanding better how the programming language that you are using is working behind the scenes!`}</p>
    <h3>{`SEARCH THE SOLUTION`}</h3>
    <p>{`While helping a junior developer, it is common to tell them the correct solution to a given problem.`}</p>
    <p>{`However, we need to teach them how to teach themselves: they need to start finding solutions on their own. That’s important so that they don’t continuously ask for help without even trying.`}</p>
    <p>{`Most of the time, the solution is few searches away on Google, Stackoverflow, Bing or whatever search engine you decide to use.`}</p>
    <p>{`However, most senior developers think that everyone knows how to search for a solution.`}</p>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/10368/1*lytSQVSSS7G3mEu26O6p-A.jpeg",
        "alt": "Alt text of image"
      }}></img></p>
    <p>{`Searching for a solution on the Internet is a skill.
Working multiple years as a software developer, we take this skill for granted but actually, it can be a complex one to master when you are at the beginning of your career.`}</p>
    <p>{`Where should a developer search for a solution? What should you search? Which string should you use while making a search? How do we navigate between tons of answers and content from a simple search? How do we move forward after we found a hint for our solution?`}</p>
    <p>{`These are a few questions that every junior developer is asking themselves when getting stuck in an error or a problem they are working on and don’t know how to find a solution.`}</p>
    <p>{`These are skills that we gain during years and years of experience.
We need to pass them these skills so that they can move faster in their career than the time took us to reach our current knowledge as a senior developer.`}</p>
    <p>{`If everyone moves faster, that’s a good thing for the team and the overall progress in the company.`}</p>
    <h3>{`BE HUMBLE`}</h3>
    <p>{`If you are a senior developer, you might learn lots of things from junior developers.`}</p>
    <p>{`They are probably learning at a higher speed than you do and they might actually teach you something back.
We can learn from anyone in your team, junior developers included. They are spending time learning new frameworks, programming languages, web services and more.`}</p>
    <p>{`As a senior developer, you might be full of tasks and with not much time to learn new things (not an excuse to stop learning!).`}</p>
    <p>{`Not only: always listen to their suggestions and new ideas. They might think ideas or implementations that could be beneficial for the team, product and the company you are working for.`}</p>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/10368/1*S8STTYUSSxKIu76Wyz9z6g.jpeg",
        "alt": "Alt text of image"
      }}></img></p>
    <p>{`The ones in this article are just a few of the things that we can do to support junior developers and help them to achieve their best potential.
The most important point we should support each other to achieve the best results for the team and not for an individual.`}</p>
    <p><strong parentName="p">{`What are your suggestions to help and support junior developers?`}</strong></p>
    <p>{`Have you enjoyed this article, leave a comment and let me know what you think on this topic!`}</p>
    <figure style={{
      position: "relative",
      overflow: "hidden",
      width: "100%",
      paddingTop: "56.25%"
    }}>
    <iframe src="https://www.youtube.com/embed/-ENU1vjNSdQ" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: "100%",
        height: "100%"
      }}></iframe>
    <figcaption className="md-figure-caption">Help Junior Developers</figcaption>
    </figure>
    <h4>{`AUTHOR`}</h4>
    <p>{`Domenico is a software developer with a passion for design, psychology, and leadership.
If you need help with software development, you need consultancy for your technical challenges or you need a leader for your software team or just want to collaborate online, feel free to contact me!`}</p>
    <h4>{`FOLLOW ME`}</h4>
    <p>{`Do you know that I have a YouTube channel? `}<a parentName="p" {...{
        "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
      }}>{`Subscribe!`}</a></p>
    <p>{`Where can you find me?`}</p>
    <p>{`Youtube: `}<a parentName="p" {...{
        "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
      }}>{`Domenico Solazzo's Channel`}</a></p>
    <p>{`Instagram: `}<a parentName="p" {...{
        "href": "https://www.instagram.com/domenicosolazzo/"
      }}>{`domenicosolazzo`}</a></p>
    <p>{`Linkedin: `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/solazzo/"
      }}>{`solazzo`}</a></p>
    <p>{`Medium: `}<a parentName="p" {...{
        "href": "https://medium.com/@domenicosolazzo"
      }}>{`domenicosolazzo`}</a></p>
    <p>{`Facebook: `}<a parentName="p" {...{
        "href": "https://www.facebook.com/domenicosolazzo.labs/"
      }}>{`domenicosolazzo`}</a></p>
    <p>{`Twitter: `}<a parentName="p" {...{
        "href": "https://twitter.com/domenicosolazzo"
      }}>{`domenicosolazzo`}</a></p>
    <p>{`Snapchat: `}<a parentName="p" {...{
        "href": "https://twitter.com/domenicosolazzo"
      }}>{`domenicosolazzo`}</a></p>
    <p>{`Github: `}<a parentName="p" {...{
        "href": "https://github.com/domenicosolazzo"
      }}>{`domenicosolazzo`}</a></p>
    <p>{`Website: `}<a parentName="p" {...{
        "href": "https://www.domenicosolazzo.com"
      }}>{`https://www.domenicosolazzo.com`}</a></p>
    <p><strong parentName="p">{`Hashnode`}</strong>{`: `}<a parentName="p" {...{
        "href": "https://hashnode.com/@domenicosolazzo"
      }}>{`https://hashnode.com/@domenicosolazzo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      